import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, from, of, EMPTY } from 'rxjs';
import { map, finalize, concatMap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Account } from '@app/_models';
import { CampaignPlatform } from '@app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '@app/_services/data.service';

const baseUrl = `${environment.apiUrl}`;
const cookiedomain = `${environment.cookiedomain}`;

declare const FB: any;

@Injectable({ providedIn: 'root' })
export class AccountService {
  private accountSubject: BehaviorSubject<Account>;
  public account: Observable<Account>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private dataService: DataService
  ) {
    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();
  }

  getPlatformAuthStatus(authId: string, platform: string) {
    return this.http
      .get<CampaignPlatform>(
        `${baseUrl}/get-campaign-platform?id=${authId}&platform=${platform}`
      )
      .pipe(
        map((platform) => {
          return platform;
        })
      );
  }

  addCampaignPlatform(authId: string, platform: string) {
    return this.http.post(`${baseUrl}/add-campaign-platform`, {
      id: authId,
      platform: platform,
      is_connected: 1,
    });
  }

  getLinkedinAccessCode(token: string) {
    return (
      this.http
        //.get<any>(`${baseUrl}/get-linkedin-access-code?accessCode=${token}`)
        .get<any>(`${baseUrl}/linkedinauth?code=${token}`)
        .pipe(
          map((response) => {
            return response;
          })
        )
    );
  }

  getLinkedinProfile(accessCode: string) {
    return this.http
      .get<any>(`${baseUrl}/get-linkedin-profile?accessCode=${accessCode}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  public get accountValue(): Account {
    return this.accountSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${baseUrl}/login`, { email, password }).pipe(
      map((account) => {
        localStorage.setItem('loggedin_user_id', account.user.id);
        this.accountSubject.next(account);
        const tempOrg = JSON.parse(localStorage.getItem('currentorg'));

        if (tempOrg) {
          this.dataService.currentOrg = tempOrg;
        } else {
          this.dataService.currentOrg = account.userorgs[0];
          localStorage.setItem(
            'currentorg',
            JSON.stringify(account.userorgs[0])
          );
        }

        this.cookieService.delete('rr-auth-token', `/`); // return req.headers["rr-auth-cookie"];
        this.cookieService.delete('rr-refresh-token', `/`); // return req.headers["rr-auth-cookie"];

        this.cookieService.set(
          'rr-auth-token',
          account.jwtToken,
          2,
          '/',
          '',
          false,
          'Lax'
        );
        this.cookieService.set(
          'rr-refresh-token',
          account.jwtRefreshToken,
          365,
          '/'
        ); // return req.headers["rr-auth-cookie"];

        console.log(cookiedomain);

        this.startRefreshTokenTimer();
        return account;
      })
    );
  }

  logout() {
    var refreshtoken = this.cookieService.get('rr-refresh-token');
    this.http
      .post<any>(`${baseUrl}/revoke-token`, { refreshtoken })
      .subscribe();
    this.stopRefreshTokenTimer();
    localStorage.removeItem('loggedin_user_id');
    localStorage.removeItem('currentorg');
    this.cookieService.delete('rr-auth-token', `/`, `${cookiedomain}`); // return req.headers["rr-auth-cookie"];
    this.cookieService.delete('rr-refresh-token', `/`, `${cookiedomain}`); // return req.headers["rr-auth-cookie"];

    this.accountSubject.next(null);
    this.router.navigate(['/']);
  }

  refreshToken() {
    var refreshtoken = this.cookieService.get('rr-refresh-token');
    return this.http
      .post<any>(`${baseUrl}/refresh-token`, { refreshtoken })
      .pipe(
        map((account) => {
          if (account) {
            this.accountSubject.next(account);
            this.cookieService.delete('rr-auth-token', `/`); // return req.headers["rr-auth-cookie"];
            this.cookieService.delete('rr-refresh-token', `/`); // return req.headers["rr-auth-cookie"];
            this.cookieService.set(
              'rr-auth-token',
              account.jwtToken,
              2,
              '/',
              '',
              false,
              'Lax'
            );
            this.cookieService.set(
              'rr-refresh-token',
              account.jwtRefreshToken,
              365,
              '/'
            ); // return req.headers["rr-auth-cookie"];
            this.startRefreshTokenTimer();
            return account;
          } else {
            this.stopRefreshTokenTimer();
            this.accountSubject.next(null);
            localStorage.clear();
            this.cookieService.delete('rr-auth-token', `/`); // return req.headers["rr-auth-cookie"];
            this.cookieService.delete('rr-refresh-token', `/`); // return req.headers["rr-auth-cookie"];
            this.router.navigate(['/account/login']);
          }
          // this.router.navigate(['/account/login']);
        })
      );
  }

  register(account: Account) {
    return this.http.post(`${baseUrl}/register`, account);
    /*Following code is for api register call*/

    // return this.registerService.register(account)
    //     .pipe(map(account => {

    //         return account;

    //     }));
  }

  acceptInvite(code, userid, org) {
    return this.http.post(`${baseUrl}/acceptinvite`, { code, userid, org });
  }

  verifyEmail(token: string) {
    return this.http.post(`${baseUrl}/verify-email`, { token: token });
  }

  forgotPassword(email: string) {
    return this.http.post(`${baseUrl}/forgot-password`, { email });
  }

  validateResetToken(token: string) {
    return this.http.post(`${baseUrl}/validate-reset-token`, { token: token });
  }

  resetPassword(token: string, password: string, confirmPassword: string) {
    return this.http.post(`${baseUrl}/reset-password`, {
      token,
      password,
      confirmPassword,
    });
  }

  getAll() {
    return this.http.get<Account[]>(`${baseUrl}/users`);
  }

  getAllByOrg(org) {
    return this.http.get<Account[]>(`${baseUrl}/users/org/${org}`);
  }

  getById(id: string) {
    return this.http.get<Account>(`${baseUrl}/users/${id}`);
  }

  getByEmail(email: string) {
    return this.http.get<Account>(`${baseUrl}/users/email/${email}`);
  }

  getByOrgAndId(org: string, id: string) {
    return this.http.get<Account>(`${baseUrl}/users/org/${org}/id/${id}`);
  }

  create(params, org) {
    return this.http.post(`${baseUrl}/users`, params).pipe(
      map((account: any) => {
        return account;
      })
    );
  }

  link(userid, orgid, role) {
    return this.http.post(`${baseUrl}/linkorg`, { userid, orgid, role }).pipe(
      map((account: any) => {
        // console.log(account);
        return account;
      })
    );
  }

  update(id, params) {
    return this.http.put(`${baseUrl}/users/${id}`, params).pipe(
      map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      })
    );
  }

  updaterole(id, role) {
    var params = {
      role: role,
    };

    return this.http
      .put(`${baseUrl}/role/${id}`, params)
      .pipe(map((account: any) => {}));
  }

  delete(id: string) {
    return this.http.delete(`${baseUrl}/users/${id}`).pipe(
      finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id) {
          this.logout();
        }
      })
    );
  }

  // helper methods

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));
    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe();
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  fbLogin() {
    return this.loginFacebook().pipe(
      concatMap((accessToken) => this.loginApi(accessToken as string))
    );
  }

  loginFacebook() {
    // login with facebook and return observable with fb access token on success
    const fbLoginPromise = new Promise<any>((resolve) => FB.login(resolve, {scope: 'ads_management,business_management'}));
    return from(fbLoginPromise).pipe(
      concatMap(({ authResponse }) =>
        authResponse ? of(authResponse.accessToken) : EMPTY
      )
    );
  }

  loginApi(accessToken: string) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token
    return this.http
      .post<any>(`${baseUrl}/fb-authenticate`, { accessToken })
      .pipe(
        map((account) => {
          this.accountSubject.next(account);
          // this.startAuthenticateTimer();
          return account;
        })
      );
  }

  getGoogleAuthUrl() {
    return this.http.get<Account>(`${baseUrl}/google_authenticate`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getGoogleRefreshToken(code: string) {
    return this.http
      .get<Account>(`${baseUrl}/google_oauth2_callback?code=${code}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
