<div *ngIf="loading" class="loading-container flex-content-center">
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<div *ngIf="!account">
  <router-outlet></router-outlet>
</div>

<div *ngIf="account">

  <mat-toolbar color="primary" style="z-index:3">
    <mat-toolbar-row>

      <!--<span class="navlogo"><h1><strong>report</strong>rover&#8482;</h1></span>-->

      <svg width="250" height="250" xmlns="http://www.w3.org/2000/svg">
        <image href="assets/images/Report_Rover_Wordmark_Light_BluePurple_TM.svg" height="250" width="250" />
      </svg>
      <div fxShow="true" fxHide.lt-md class="rr_topmenu">
        <a href="/leads/dashboard" mat-button [ngClass]="router.url === '/leads/dashboard'?'activepage':''" >Dashboard </a>
        <a href="/leads/lead-view" mat-button [ngClass]="router.url === '/leads/lead-view' || router.url==='/leads/lead-breakdown' ?'activepage':''">Leads</a>
        <a href="/leads/website-performance" mat-button [ngClass]="router.url === '/leads/website-performance'?'activepage':''">My Website's Performance</a>
        <a href="/leads/simpledownload" mat-button [ngClass]="router.url === '/leads/simpledownload'?'activepage':''">Data Download</a>
        <a href="/leads/campaign-creator" mat-button [ngClass]="router.url.includes('/leads/campaign-creator')?'activepage':''">Campaign Creator</a>
        <a href="/leads/campaign-performance" mat-button [ngClass]="router.url === '/leads/campaign-performance'?'activepage':''">Campaign Performance</a>
      </div>
      <span class="menu-spacer-large"></span>
      <div class="rr_topmenu sn-btns d-flex align-items-center justify-content-end">
          <label style="width: 30px">Viewing:</label>
          <div class="accountDD ms-3 dropdown">
              <div class="accountDD ms-3 dropdown">
                  <button class="aDDbtn aDDbtnYellow btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <span>{{dataService.currentOrg.name}}</span>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li *ngFor="let org of account.userorgs">
                          <a class="dropdown-item" (click)="changeOrg(org)">{{org.name}}</a>
                      </li>

                  </ul>
              </div>
          </div>
      </div>

      <div class="rr_topmenu sn-btns text-center" (click)="myAccount()" style="cursor: pointer;">
        <i class="fas fa-user" style="font-size: 20px; color: #2952CC;"></i>
        <div style="margin-top: -10px;">My Profile</div>
      </div>
      <div class="rr_topmenu sn-btns text-center" (click)="showNotifications()" style="cursor: pointer; position: relative;">
        <i class="fas fa-bell" style="font-size: 20px; color: #2952CC;"></i>
        <div style="margin-top: -10px;">Notifications</div>
        <span class="notification-badge">2</span>
      </div>
      <div class="rr_topmenu sn-btns text-center" (click)="logout()" style="cursor: pointer;">
        <i class="fas fa-right-from-bracket" style="font-size: 20px; color: #2952CC;"></i>
        <div style="margin-top: -10px;">Logout</div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
  <!--  <mat-sidenav #sidenav>
      <mat-nav-list class="rr_topmenu">
        <a (click)="sidenav.toggle()" href="" mat-list-item>Close</a>
        <a href="/#/leads/dashboard" mat-button [ngClass]="router.url === '/leads/dashboard'?'activepage':''" >Dashboard </a>
        <a href="/#/leads/lead-view" mat-button [ngClass]="router.url === '/leads/lead-view' || router.url==='/leads/lead-breakdown' ?'activepage':''">Leads</a>
        <a href="/#/leads/website-performance" mat-button [ngClass]="router.url === '/leads/website-performance'?'activepage':''">My Website's Performance</a>
      </mat-nav-list>
    </mat-sidenav>-->
    <mat-sidenav-content fxFlexFill>
      <alert></alert>

      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>


  <!-- Site-wide verification failed modal here -->
  <!-- Button trigger modal -->
<!--<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  Launch static backdrop modal
</button>-->

<!-- Modal -->
<div class="modal fade" id="verifyError" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="verifyErrorLabel" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="verifyErrorLabel">Verification Failed</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p>
					<i class="fa-solid fa-circle-exclamation" style="color:red"></i>
					We were unable to detect a valid installation of your code snippet.
				</p>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary">I will fix it!</button>
				<span>For additional assistance, please visit our <a href="#">Support Page</a></span>
			</div>
		</div>
	</div>
</div>
</div>
<app-notification [(isNotificationOpen)]="isNotificationModalOpen" (isNotificationOpenChange)="onNotificationOpenChange($event)"></app-notification>