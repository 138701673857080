<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Creator
                <p class="campaign-name-title">{{ "Linkedin: " }}</p>
			</div>
		</div>
	</div>
</div>

<div class="card">
    <div class="progress-bar">
        <div class="label-pane font-gray font-italic">
            <span>Linkedin Campaign</span>
            <span>Steps: 1 of 3</span>
        </div>
    </div>
    <mat-progress-bar class="setting-step" mode="determinate" value="33"></mat-progress-bar>

    <h4 class="card-title">Create the ad</h4>

    <div class="intro-form common-form">
        <div class="common-label">
            <label>Introductory text</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <div class="intro-text common-text">
            <textarea name="intro-text" rows="5" maxlength="600" placeholder="Introductory text" [(ngModel)]="introText" (input)="updateLetterCount()"></textarea>
            <p class="font-light-gray font-italic intro-count text-count">{{ letterCount }}/600</p>
        </div>
    </div>

    <div class="file-form">
        <label>File upload</label>
        <p class="sub-label font-gray font-italic">Image must be a JPG, PNG, or GIF; up to 5GB</p>

        <div class="upload-panel" [ngClass]="{'selected': imageSrc}" [style.backgroundImage]="'url(' + previewImageSrc + ')'">
            <button class="btn-common btn-upload btn-primary" (click)="openUploadModal()">Upload or Select</button>
        </div>
    </div>

    <div class="headline-form common-form">
        <div class="common-label">
            <label>Headline</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <div class="common-text">
            <textarea name="headline" rows="3" maxlength="200" placeholder="Example headline" [(ngModel)]="headlineText" (input)="updateHeadlineCount()"></textarea>
            <p class="font-light-gray font-italic text-count">{{ headlineCount }}/200</p>
        </div>
    </div>

    <div class="destination-form common-form">
        <div class="common-label">
            <label>Destination URL</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <div class="common-text">
            <input type="text" class="destination-text" name="destination" placeholder="www.examplewebsite.com" [(ngModel)]="destinationUrl" (focusout)="onDestinationUrlChange()" />
        </div>
    </div>

    <div class="common-form call-to-action-form">
        <div class="common-label">
            <label>Call-to-action</label>
        </div>

        <div class="accountDD dropdown">
            <button class="aDDbtn aDDbtnBlue btn btn-common btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{selectedAction}}</span>
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let action of actions">
                    <a class="dropdown-item" (click)="changeAction(action)">{{action}}</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="ad-preview">
        <label class="font-bold">Ad Preview</label>
        <div class="preview-container">
            <div class="logo-panel" *ngIf="!fbId">
                <img [src]="logo ? logo : '/assets/images/default_logo.png'" alt="Your Logo">
                <div class="company-info">
                    <p class="company-name font-bold">{{companyName ? companyName : 'Company name'}}</p>
                    <p class="follower-count font-gray">{{followerCount}} followers</p>
                    <p class="status font-gray">Promoted</p>
                </div>
            </div>
            <p class="intro-text-preview font-bold">{{introText ? introText : 'Intro text and caption goes here!'}}</p>
            <img [src]="imageSrc" alt="Preview Image" class="content-image">
            <div class="headline-pane">
                <div>
                    <p class="headline-preview font-bold">{{headlineText ? headlineText : 'Headline Goes Here'}}</p>
                    <p class="destination-preview font-gray">{{destinationUrl}}</p>
                </div>
                <a class="btn-common btn-default btn-learn-more">Learn more</a>
            </div>
        </div>
    </div>

    <div class="btn-container">
        <button class="btn-common btn-back btn-default" (click)="backToCampaign()">Back</button>
        <button class="btn-common btn-save btn-primary" (click)="createAd()">Save</button>
    </div>
</div>

<app-file-upload-modal [(isOpen)]="isUploadModalOpen" (imageSelected)="onImageSelectHandler($event)"></app-file-upload-modal>
