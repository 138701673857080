import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/store/models/state.model';
import { Campaign } from '@app/store/models/campaign.model';
import { LinkedinAd } from '@app/store/models/linkedinAd.model';
import { Setting } from '@app/store/models/setting.model';
import { CampaignService, DataService } from '@app/_services';

interface Industry {
  id: number;
  name: string;
}

@Component({
  selector: 'app-ad-creator2',
  templateUrl: './ad-creator2.component.html',
  styleUrls: ['./ad-creator2.component.scss'],
})
export class AdCreator2Component implements OnInit {
  settings$: Observable<Setting>;

  introText: string = '';
  headlineText: string = '';
  destinationUrl: string = '';
  logo: string = '';
  followerCount: number = 0;
  companyName: string = '';
  imageSrc: string = '';
  imageUrn: string = '';
  settingId: number = 0;
  adId: number = 0;
  revenues: string[] = [];
  employees: string[] = [];
  industries: Industry[] = [];
  selectedIndustries: Industry[] = [];
  selectedAction: any;
  linkedinShareId: any;

  linkedinAdId: number = 0;
  timeframe: number = 0;
  platformId: string = '';
  dailyBudget: string = '$00.00 USD';
  threshold: string = '$00.00 USD';

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private campaignService: CampaignService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.store.pipe(select('settings')).subscribe((data: Setting) => {
      this.settingId = data.id;
      this.logo = data.logo;
      this.followerCount = data.followerCount;
      this.companyName = data.companyName;
      this.linkedinAdId = data.linkedinAdId;
      this.introText = data.introText;
      this.headlineText = data.headline;
      this.destinationUrl = data.destinationUrl;
      this.imageSrc = data.image;
      this.imageUrn = data.imageUrn;
      this.revenues = data.revenue;
      this.employees = data.employees;
      this.selectedIndustries = data.industries;
      this.selectedAction = data.callToAction;
      this.linkedinShareId = data.linkedinShareId;


    });

    this.store.pipe(select('campaigns')).subscribe((data: Campaign) => {
      this.threshold = '$' + Number(data.threshold).toFixed(2) + ' USD';
    });

    this.store.pipe(select('linkedinAds')).subscribe((data: LinkedinAd) => {
      this.timeframe = data.timeframe;
    });
  }

  backToFirstStep() {
    this.router.navigate(['/leads/campaign-creator/first-step']);
  }

  editCustomSetting() {
    this.router.navigate(['/leads/campaign-creator']);
  }

  saveAd() {
    if (this.dataService.campaign) {

      this.editLinkedinAd();
    } else {
      this.createLinkedinAd();
    }
  }

  createLinkedinAd() {
    const fbId = localStorage.getItem('fbId');
    const gAuthId = localStorage.getItem('google-auth-id');

    if (gAuthId) {
      this.router.navigate(['/leads/campaign-creator/complete']);
    } else if (fbId) {
      const formData = {
        id: this.settingId,
        platformId: fbId,
      };

      this.campaignService
        .createMetaAd(formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            console.log(res);
            this.router.navigate(['/leads/campaign-creator/complete']);
          },
          error: () => {
            console.log('Error');
          },
        });
    } else {
      const formData = {
        accessCode: localStorage.getItem('linkedin-access-code'),
        id: this.dataService.settingId,
      };

      console.log(formData);

      this.campaignService
        .createLinkedinAd(formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            this.router.navigate(['/leads/campaign-creator/complete']);
          },
          error: () => {
            console.log('Error');
          },
        });
    }
  }

  editLinkedinAd() {

    const fbId = localStorage.getItem('fbId');
    const gAuthId = localStorage.getItem('google-auth-id');

    if (gAuthId) {
      this.router.navigate(['/leads/campaign-creator/complete']);
    } else if (fbId) {
      const formData = {
        id: this.settingId,
        platformId: fbId,
      };

      this.campaignService
        .createMetaAd(formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            console.log(res);
            this.router.navigate(['/leads/campaign-creator/complete']);
          },
          error: () => {
            console.log('Error');
          },
        });
    } else {
      const formData = {
        accessCode: localStorage.getItem('linkedin-access-code'),
        id: this.dataService.settingId,
        campaign: this.dataService.campaign,
        introText: this.introText,
        postid: this.linkedinShareId.replaceAll(":","%3A"),
        action: this.selectedAction.replace(" ","_").toUpperCase(),
        actionurl: this.destinationUrl

      };

      this.campaignService
        .updateLinkedinAd(this.dataService.campaign.id, formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            this.router.navigate(['/leads/campaign-creator/complete']);
          },
          error: () => {
            console.log('Error');
          },
        });
    }
  }
}
