import { Campaign } from '../models/campaign.model';
import { CampaignAction, CampaignActionType } from '../actions/campaigns.actions';

const initialState: Campaign = {
    platformId: '',
    threshold: 0,
    platform: 'linkedin'
};

export function CampaignReducer(
    state: Campaign = initialState,
    action: CampaignAction
) {
    switch (action.type) {
        case CampaignActionType.ADD_ITEM:
            return action.payload;
        default:
            return state;
    }
}
