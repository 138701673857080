<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Creator
                <p class="campaign-name-title">{{ "Google: " }}</p>
			</div>
		</div>
	</div>
</div>

<div class="card">
    <div class="progress-bar">
        <div class="label-pane font-gray font-italic">
            <span>Google Campaign</span>
            <span>Steps: 1 of 3</span>
        </div>
    </div>
    <mat-progress-bar class="setting-step" mode="determinate" value="33"></mat-progress-bar>

    <h4 class="card-title">Create the ad</h4>

    <div class="intro-form common-form">
        <div class="common-label">
            <label>Headline</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <p class="sub-label-text">Up to 5 headlines</p>
        <div *ngFor="let headline of headlines; let i =index" class="intro-text common-text">
            <span class="count-label">{{ i + 1 }}</span>
            <textarea name="headline" rows="1" maxlength="30" placeholder="Example headline" [(ngModel)]="headlines[i].text" (input)="updateLetterCount(i)"></textarea>
            <p class="font-light-gray font-italic intro-count text-count">{{ headlines[i].letterCount }}/30</p>
        </div>

        <button
            *ngIf="headlines.length < 5"
            class="btn-common btn-add btn-primary"
            (click)="addHeadline()"
        >+ ADD ANOTHER HEADLINE</button>
    </div>

    <div class="long-headline-form common-form">
        <div class="common-label">
            <label>Long headline</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <div class="common-text">
            <textarea name="long-headline" rows="2" maxlength="90" placeholder="Example long headline" [(ngModel)]="longHeadlineText" (input)="updateLongHeadlineCount()"></textarea>
            <p class="font-light-gray font-italic text-count">{{ longHeadlineCount }}/90</p>
        </div>
    </div>

    <div class="description-form common-form">
        <div class="common-label">
            <label>Description</label>
            <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
        </div>
        <p class="sub-label-text">Up to 5 descriptions</p>
        <div *ngFor="let desc of descriptions; let i =index" class="description-text common-text">
            <span class="count-label">{{ i + 1 }}</span>
            <textarea name="description" rows="2" maxlength="90" placeholder="Example description" [(ngModel)]="descriptions[i].text" (input)="updateDescriptionCount(i)"></textarea>
            <p class="font-light-gray font-italic intro-count text-count">{{ descriptions[i].letterCount }}/90</p>
        </div>

        <button
            *ngIf="descriptions.length < 5"
            class="btn-common btn-add btn-primary"
            (click)="addDescription()"
        >+ ADD ANOTHER DESCRIPTION</button>
    </div>

    <div class="common-form call-to-action-form">
        <div class="common-label">
            <label>Call-to-action</label>
        </div>

        <div class="accountDD dropdown">
            <button class="aDDbtn aDDbtnBlue btn btn-common btn-white dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <span>{{selectedAction}}</span>
            </button>
            <ul class="dropdown-menu">
                <li *ngFor="let action of actions">
                    <a class="dropdown-item" (click)="changeAction(action)">{{action}}</a>
                </li>
            </ul>
        </div>
    </div>

    <div class="file-form">
        <label>Upload your logo</label>
        <p class="sub-label font-gray font-italic">Logos must be a JPG, PNG, or GIF; up to 5GB</p>

        <div class="upload-panel" [ngClass]="{'selected': logoSrc}" [style.backgroundImage]="'url(' + previewLogoSrc + ')'">
            <button class="btn-common btn-upload btn-primary" (click)="openUploadModal('logo')">Upload or Select</button>
        </div>
    </div>

    <div class="file-form">
        <label>Upload photos (multiple recommended)</label>
        <p class="sub-label font-gray font-italic">Images must be a JPG, PNG, or GIF; up to 5GB - up to 15 images, one landscape</p>

        <div class="upload-panel" [ngClass]="{'selected': imageSrc}" [style.backgroundImage]="'url(' + previewImageSrc + ')'">
            <button class="btn-common btn-upload btn-primary" (click)="openUploadModal('image')">Upload or Select</button>
        </div>
    </div>

    <div class="ad-preview">
        <label class="font-bold">Ad Preview</label>
        <!-- <div class="preview-container">
            <div class="logo-panel">
                <img [src]="logo ? logo : '/assets/images/default_logo.png'" alt="Your Logo">
                <div class="company-info">
                    <p class="company-name font-bold">{{companyName ? companyName : 'Company name'}}</p>
                    <p class="follower-count font-gray">{{followerCount}} followers</p>
                    <p class="status font-gray">Promoted</p>
                </div>
            </div>
            <p class="intro-text-preview font-bold">{{introText ? introText : 'Intro text and caption goes here!'}}</p>
            <img [src]="imageSrc" alt="Preview Image" class="content-image">
            <div class="headline-pane">
                <div>
                    <p class="headline-preview font-bold">{{headlineText ? headlineText : 'Headline Goes Here'}}</p>
                    <p class="destination-preview font-gray">{{destinationUrl}}</p>
                </div>
                <a class="btn-common btn-default btn-learn-more">Learn more</a>
            </div>
        </div> -->
    </div>

    <div class="btn-container">
        <button class="btn-common btn-back btn-default" (click)="backToCampaign()">Back</button>
        <button class="btn-common btn-save btn-primary" (click)="createAd()">Save</button>
    </div>
</div>

<app-file-upload-modal [(isOpen)]="isUploadModalOpen" (imageSelected)="onImageSelectHandler($event)"></app-file-upload-modal>
