import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CampaignService } from '@app/_services';
import { Store, select } from '@ngrx/store';
import { Setting } from '@app/store/models/setting.model';
import { AppState } from '@app/store/models/state.model';
import { AddSettingAction } from '@app/store/actions/settings.actions';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-ad-creator1',
  templateUrl: './ad-creator1.component.html',
  styleUrls: ['./ad-creator1.component.scss'],
})
export class AdCreator1Component implements OnInit {
  settings$: Observable<Setting>;

  isUploadModalOpen: boolean = false;

  introText: string = '';
  letterCount: number = 0;

  headlineText: string = '';
  headlineCount: number = 0;

  actions: Array<string> = [
    'Apply',
    'Download',
    'View Quote',
    'Learn more',
    'Sing Up',
    'Subscribe',
    'Register',
    'Join',
    'Attend',
    'Request Demo',
  ];
  selectedAction: string = 'Select option...';

  destinationUrl: string = '';
  logo: string = '';
  followerCount: number = 0;
  companyName: string = '';

  previewImageSrc: string = '';
  imageSrc: string = '';
  selectedImageURN: string = '';
  individualAdId: number = 0;
  adSetting: number = 0;
  settingId: number = 0;
  revenue: string[] = [];
  employees: string[] = [];
  industries: [] = [];

  fbId: string = '';
  googleAuthId: string = '';

  constructor(
    private campaignService: CampaignService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.fbId = localStorage.getItem('fbId');
    this.googleAuthId = localStorage.getItem('google-auth-id');

    this.store.pipe(select('settings')).subscribe((data: Setting) => {
      this.settingId = data.id;
      this.individualAdId = data.linkedinAdId;
      this.adSetting = data.launchPreference;
      this.revenue = data.revenue;
      this.employees = data.employees;
      this.industries = data.industries;
      this.introText = data.introText;
      this.headlineText = data.headline;
      this.destinationUrl = data.destinationUrl;
      this.imageSrc = data.image;
      this.selectedImageURN = data.imageUrn;
      this.previewImageSrc = "'" + data.image + "'";
      this.selectedAction = data.callToAction;

      this.onDestinationUrlChange();
    });
  }

  updateLetterCount() {
    this.letterCount = this.introText.length;
  }

  updateHeadlineCount() {
    this.headlineCount = this.headlineText.length;
  }

  changeAction(action: string) {
    this.selectedAction = action;
  }

  openUploadModal() {
    this.isUploadModalOpen = true;
  }

  onDestinationUrlChange() {
    const accessCode = localStorage.getItem('linkedin-access-code');

    if (accessCode) {
      this.campaignService
        .getOrganizationInfo(this.destinationUrl, accessCode)
        .subscribe((data: any) => {
          this.logo = data.logo;
          this.followerCount = data.followerCount;
          this.companyName = data.name;
        });
    }
  }

  onImageSelectHandler(event: any) {
    this.previewImageSrc =
      "'" + environment.apiUrl + '/show_image?src=' + event.src + "'";
    this.imageSrc = environment.apiUrl + '/show_image?src=' + event.src;
    this.selectedImageURN = event.urn;
  }

  createAd() {
    if (this.individualAdId) {
      const formData = {
        linkedin_ad_id: this.individualAdId,
        launch_preference: this.adSetting,
        intro_text: this.introText,
        image: this.imageSrc,
        linkedin_image_urn: this.selectedImageURN,
        headline: this.headlineText,
        destination_url: this.destinationUrl,
        call_to_action: this.selectedAction,
      };

      if (this.settingId > 0) {
        this.campaignService
          .updateCustomSetting(this.settingId, formData)
          .pipe(first())
          .subscribe({
            next: (res) => {
              if (res['affectedRows'] > 0) {
                this.store.dispatch(
                  new AddSettingAction({
                    id: this.settingId,
                    linkedinAdId: this.individualAdId,
                    launchPreference: this.adSetting,
                    revenue: this.revenue,
                    employees: this.employees,
                    industries: this.industries,
                    introText: this.introText,
                    headline: this.headlineText,
                    image: this.imageSrc,
                    imageUrn: this.selectedImageURN,
                    destinationUrl: this.destinationUrl,
                    callToAction: this.selectedAction,
                    logo: this.logo,
                    companyName: this.companyName,
                    followerCount: this.followerCount,
                  })
                );

                this.router.navigate(['/leads/campaign-creator/second-step']);
              }
            },
            error: () => {
              console.log('Error');
            },
          });
      } else {
        this.campaignService
          .insertCustomSetting(formData)
          .pipe(first())
          .subscribe({
            next: (res) => {
              if (res['insertId'] > 0) {
                this.store.dispatch(
                  new AddSettingAction({
                    id: res['insertId'],
                    linkedinAdId: this.individualAdId,
                    launchPreference: this.adSetting,
                    revenue: this.revenue,
                    employees: this.employees,
                    industries: this.industries,
                    introText: this.introText,
                    headline: this.headlineText,
                    image: this.imageSrc,
                    imageUrn: this.selectedImageURN,
                    destinationUrl: this.destinationUrl,
                    callToAction: this.selectedAction,
                    logo: this.logo,
                    companyName: this.companyName,
                    followerCount: this.followerCount,
                  })
                );
                this.router.navigate(['/leads/campaign-creator/second-step']);
              }
            },
            error: () => {
              console.log('Error');
            },
          });
      }
    }
  }

  backToCampaign() {
    this.router.navigate(['/leads/campaign-creator']);
  }
}
