import { finalize } from 'rxjs/operators';

import { AccountService } from '@app/_services';
import { environment } from '@environments/environment';
import { FacebookService } from '@app/_services/facebook.service';

export function appInitializer(accountService: AccountService) {
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate

        // need to check the route in case this is a reset password link
        if (location.hash.split('?')[0] ===  "#/account/reset-password") {
            resolve(null);
        } else if (location.hash.split('?')[0].startsWith("#/account/verify-email")) {
            resolve(null);            
        } else if (location.hash.split('?')[0].startsWith("#/account/accept-invite")) {
                resolve(null);
        } else {


            accountService.refreshToken()
                .subscribe()
                .add(resolve);
        }


    });
}

export function initializeApp(facebookService: FacebookService) {
    return () => facebookService.isSdkReady();
}