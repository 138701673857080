import { NgModule, APP_INITIALIZER } from "@angular/core";
import {provideClientHydration} from '@angular/platform-browser';
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from "@angular/common/http";
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { appInitializer } from "./_helpers";
import { JwtInterceptor, ErrorInterceptor } from "./_interceptors";
import { AccountService } from "./_services";
import { AlertComponent } from "./_components";
import { RefererComponent } from "./_components";
import { CompanyJourneyComponent } from "./_components";

import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AuthInterceptor } from "./_interceptors";
import { LoadingInterceptor } from "./_interceptors";

import { LeadsModule } from "./leads/leads.module";

import { CommonModule } from "@angular/common";

import { DataService } from "./_services/data.service";
import { RandomCompanyService } from "./random-company.service";

import { MatSliderModule } from "@angular/material/slider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatNativeDateModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReactiveFormsModule } from "@angular/forms";
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from "@angular/common";
import { DurationPipeModule } from "./pipes/durationpipe.module";
import { DurationPipe2Module } from "./pipes/durationpipe2.module";
import { ReferralPipeModule } from "./pipes/referralpipe.module";
import { CleanReferralPipeModule } from "./pipes/cleanreferralpipe.module";
import { RandomPagePipeModule } from "./pipes/randompagepipe.module";
import { JourneyComponent } from "./_components/journey.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { FlexLayoutModule } from "@angular/flex-layout";

import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { StoreModule } from '@ngrx/store';
import { CampaignReducer } from "./store/reducers/campaign.reducer";
import { LinkedinAdReducer } from "./store/reducers/linkedinAd.reducer";
import { SettingReducer } from "./store/reducers/setting.reducer";
import { NotificationComponent } from './_components/notification/notification.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    RefererComponent,
    JourneyComponent,
    CompanyJourneyComponent,
    NotificationComponent
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    LeadsModule,
    HttpClientXsrfModule.withOptions({
        cookieName: "Report-Rover-Cookie",
        headerName: "Report-Rover-Header"
    }),
    AppRoutingModule,
    GridAllModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    MatSliderModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    ReferralPipeModule,
    CleanReferralPipeModule,
    DurationPipeModule,
    DurationPipe2Module,
    RandomPagePipeModule,
    NgxDaterangepickerMd.forRoot(),
    StoreModule.forRoot({
      campaigns: CampaignReducer,
      linkedinAds: LinkedinAdReducer,
      settings: SettingReducer
    })
  ],
  providers: [ { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  DataService,
  RandomCompanyService,
  [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  provideClientHydration()
],
  bootstrap: [AppComponent]
})
export class AppModule { }
