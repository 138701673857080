import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { take, takeUntil, first, map, filter } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { AccountService } from '@app/_services';
import { CampaignService, DataService } from '@app/_services';
import { LinkedIn } from 'ng2-cordova-oauth/core';
import { OauthBrowser } from 'ng2-cordova-oauth/platform/browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Campaign } from '@app/store/models/campaign.model';
import { LinkedinAd } from '@app/store/models/linkedinAd.model';
import { Setting } from '@app/store/models/setting.model';
import { AppState } from '@app/store/models/state.model';
import { AddCampaignAction } from '@app/store/actions/campaigns.actions';
import {
  AddLinkedinAdAction,
  RemoveLinkedinAdAction,
} from '@app/store/actions/linkedinAds.actions';
import { AddSettingAction } from '@app/store/actions/settings.actions';

import { environment } from '@environments/environment';

interface Industry {
  id: number;
  name: string;
}

interface AdAccount {
  id: number;
  name: string;
  companyUrn: string;
}

interface MetaAdAccount {
  account_id: string;
  id: string;
  business: string;
}

interface MetaBusiness {
  id: string;
  name: string;
}

interface CheckBoxType {
  name: string;
  checked: boolean;
}
@Component({
  selector: 'app-campaign-creator',
  templateUrl: './campaign-creator.component.html',
  styleUrls: ['./campaign-creator.component.scss'],
})
export class CampaignCreatorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  campaigns$: Observable<Campaign>;
  linkedinAds$: Observable<LinkedinAd>;
  settings$: Observable<Setting>;

  isStartModalOpen: boolean = false;
  isBudgetOpen: boolean = false;
  selectedSetting: number = 0;
  campaignName: string = '';
  individualAdId: number = 0;
  linkedinShareId: string = "";

  errorMsg: string;

  auth2: any;
  @ViewChild('loginRef') loginElement: ElementRef;
  googleAuthId: string = '';

  private oauth: OauthBrowser = new OauthBrowser();
  private linkedinProvider: LinkedIn = new LinkedIn({
    clientId: environment.linkedinClientId,
    appScope: [
      'r_basicprofile',
      'email',
      'profile',
      'rw_organization_admin',
      'w_member_social',
      'rw_organization_admin',
      'r_organization_social',
      'w_organization_social',
      'rw_ads',
      'r_ads_reporting',
    ],
    redirectUri:  `${environment.homepage}/leads/campaign-creator`,// 'http://localhost:4200/leads/campaign-creator',
    responseType: 'code',
    state: this.generateState(16),
  });

  linkedinToken: string = '';
  linkedinAccessCode: string = '';
  linkedinId: string = '';
  fbToken: string = '';
  fbId: string = '';
  fbAccessToken: string = '';

  isConnected: boolean = false;
  isAdInfoShow: boolean = false;
  isMetaAdInfoShow: boolean = false;

  platform: string = '';
  platformId: string = '';
  platformType: string = '';

  revenues: CheckBoxType[] = [];

  selectedRevenues: string[] = [];
  isRevenueSaved: boolean = false;

  employees: CheckBoxType[] = [];

  selectedEmployees: string[] = [];
  isEmployeeSaved: boolean = false;

  adSettings: Array<number> = [0, 1];
  selectedAdSetting: number = 0;

  color: ThemePalette = 'primary';

  industries: Industry[] = [];
  adAccounts: AdAccount[] = [];
  metaAdAccounts: MetaAdAccount[] = [];
  metaBusinesses: MetaBusiness[] = [];

  protected selectedIndustries: Industry[];

  protected googleBudget: number = 0;
  protected googleThreshold: number = 0;
  protected linkedinThreshold: number = 0;
  public formattedThreshold: string = '$00.00';
  protected fbBudget: number = 0;
  protected fbThreshold: number = 0;

  linkedinAds: [] = [];

  public industryMultiCtrl: FormControl = new FormControl();
  public industryMultiFilterCtrl: FormControl = new FormControl();
  public filteredIndustriesMulti: ReplaySubject<Industry[]> = new ReplaySubject<
    Industry[]
  >(1);
  public isIndustrySaved: boolean = false;

  public adAccountCtrl: FormControl = new FormControl();
  public adAccountFilterCtrl: FormControl = new FormControl();
  public filteredAdAccount: ReplaySubject<AdAccount[]> = new ReplaySubject<
    AdAccount[]
  >(1);

  public metaAdAccountCtrl: FormControl = new FormControl();
  public metaAdAccountFilterCtrl: FormControl = new FormControl();
  public filteredMetaAdAccount: ReplaySubject<MetaAdAccount[]> = new ReplaySubject<
    MetaAdAccount[]
  >(1);

  public metaBusinessCtrl: FormControl = new FormControl();
  public metaBusinessFilterCtrl: FormControl = new FormControl();
  public filteredMetaBusiness: ReplaySubject<MetaBusiness[]> = new ReplaySubject<
    MetaBusiness[]
  >(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private accountService: AccountService,
    private campaignService: CampaignService,
    private dataService: DataService,
    private http: HttpClient,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.googleAuthSDK();

    const googleId = localStorage.getItem('google-auth-id');
    if (googleId) {
      this.platform = 'google';
      this.accountService
        .getPlatformAuthStatus(googleId, 'google')
        .subscribe((data: any) => {
          if (data.id > 0) {
            this.googleAuthId = data.platform_id;
            this.googleBudget = data.daily_budget;
            this.googleThreshold = data.budget_threshold;
          }
        });
    }

    const fbId = localStorage.getItem('fbId');
    if (fbId) {
      this.platform = 'meta';
      this.accountService
        .getPlatformAuthStatus(fbId, 'meta')
        .subscribe((data: any) => {
          if (data.id > 0) {
            this.fbId = data.platform_id;
            this.fbBudget = data.daily_budget;
            this.fbThreshold = data.budget_threshold;

            this.isMetaAdInfoShow = data.ad_account_id > 0 ? false : true;
          }
        });
    }

    const linkedinAccessCode = localStorage.getItem('linkedin-access-code');
    if (linkedinAccessCode) {
      this.platform = 'linkedin';
      this.accountService
        .getLinkedinProfile(linkedinAccessCode)
        .subscribe((data: any) => {
          this.accountService
            .getPlatformAuthStatus(data.id, 'linkedin')
            .subscribe((data: any) => {
              if (data.id > 0) {
                this.isAdInfoShow = data.ad_account_id > 0 ? false : true;
                this.isConnected = true;
                this.linkedinId = data.platform_id;
                this.linkedinThreshold = data.budget_threshold;
                this.formattedThreshold =
                  '$' + Number(data.budget_threshold).toFixed(2);

                this.campaignService
                  .getIndividualAds(data.platform_id)
                  .subscribe((data: any) => {

                    this.linkedinAds = data.results;
                  });
              }
            });
        });
    }

    this.campaignService.getRevenues().subscribe((data: any) => {
      data.results.map((result) => {
        result.checked = false;
      });

      this.revenues = data.results;
    });

    this.campaignService.getEmployees().subscribe((data: any) => {
      data.results.map((result) => {
        result.checked = false;
      });

      this.employees = data.results;
    });

    this.campaignService.getIndustries().subscribe((data: any) => {
      this.industries = data.results;

      // load the initial bank list
      this.filteredIndustriesMulti.next(this.industries.slice());

      // listen for search field value changes
      this.industryMultiFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterIndustriesMulti();
        });
    });

    if (linkedinAccessCode) {
      this.campaignService
        .getAdAccounts(linkedinAccessCode)
        .subscribe((data: any) => {
          this.adAccounts = data.results;
          this.filteredAdAccount.next(this.adAccounts.slice());
          this.adAccountFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterAdAccount();
            });
        });
    }

    if (fbId) {
      const accessToken = localStorage.getItem(`fblst_${environment.facebookAppId}`);

      if (!accessToken) {
        this.accountService.fbLogin()


      }

      this.campaignService
        .getMetaBusinesses(accessToken)
        .subscribe((data: any) => {
          this.metaBusinesses = data.results;
          this.filteredMetaBusiness.next(this.metaBusinesses.slice());
          this.metaBusinessFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterMetaBusiness();
            });
        });
    }

    // this.store.pipe(select('campaigns')).subscribe((data: Campaign) => {
    //   console.log(data);
    // });

    this.store.pipe(select('linkedinAds')).subscribe((data: LinkedinAd) => {
      this.campaignName = data.name;
      this.selectedSetting = data.adPreference;
      this.individualAdId = data.id;
    });

    this.store.pipe(select('settings')).subscribe((data: Setting) => {
      this.dataService.settingId = data.id;
      this.selectedAdSetting = data.launchPreference;

      this.revenues.forEach((revenue) => {
        if (data.revenue.includes(revenue.name)) {
          revenue.checked = true;
        }
      });
      this.revenueChange();

      this.employees.forEach((employee) => {
        if (data.employees.includes(employee.name)) {
          employee.checked = true;
        }
      });
      this.employeeChange();

      this.industryMultiCtrl.setValue(data.industries);
    });

    this.route.queryParams.subscribe((params) => {
      this.accountService
        .getGoogleRefreshToken(params['code'])
        .subscribe((data: any) => {
          console.log(data);
        });
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredIndustriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Industry, b: Industry) =>
          a && b && a.id === b.id;
      });
  }

  protected filterIndustriesMulti() {
    if (!this.industries) {
      return;
    }
    // get the search keyword
    let search = this.industryMultiFilterCtrl.value;
    if (!search) {
      this.filteredIndustriesMulti.next(this.industries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredIndustriesMulti.next(
      this.industries.filter(
        (industry) => industry.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterAdAccount() {
    if (!this.adAccounts) {
      return;
    }
    // get the search keyword
    let search = this.adAccountFilterCtrl.value;
    if (!search) {
      this.filteredAdAccount.next(this.adAccounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the AdAccounts
    this.filteredAdAccount.next(
      this.adAccounts.filter(
        (account) => account.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterMetaAdAccount() {
    if (!this.metaAdAccounts) {
      return;
    }
    // get the search keyword
    let search = this.metaAdAccountFilterCtrl.value;
    if (!search) {
      this.filteredMetaAdAccount.next(this.metaAdAccounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the AdAccounts
    this.filteredMetaAdAccount.next(
      this.metaAdAccounts.filter(
        (account) => account.id.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterMetaBusiness() {
    if (!this.metaBusinesses) {
      return;
    }
    // get the search keyword
    let search = this.metaBusinessFilterCtrl.value;
    if (!search) {
      this.filteredMetaBusiness.next(this.metaBusinesses.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the AdAccounts
    this.filteredMetaBusiness.next(
      this.metaBusinesses.filter(
        (account) => account.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  callLogin() {
    this.auth2.attachClickHandler(
      this.loginElement?.nativeElement,
      {},
      (googleAuthUser: any) => {
        const profile = googleAuthUser.getBasicProfile();
        const token = googleAuthUser.getAuthResponse().id_token;
        this.googleAuthId = profile.getId();
        localStorage.setItem('google-auth-id', this.googleAuthId);
        localStorage.removeItem('fbId');
        localStorage.removeItem('linkedin-access-code');
        localStorage.removeItem('linkedin-auth-code');
        const name = profile.getName();
        const email = profile.getEmail();
        this.platform = 'google';

        this.accountService
          .addCampaignPlatform(this.googleAuthId, 'google')
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              console.log(res);
            },
            error: () => {
              console.log('Error');
            },
          });

        this.accountService.getGoogleAuthUrl().subscribe((data: any) => {
          location.href = data.result;
        });

        this.cdr.detectChanges();
      },
      (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      }
    );
  }

  googleAuthSDK() {
    (<any>window)['googleSDKLoaded'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.auth2 = (<any>window)['gapi'].auth2.init({
          client_id: environment.googleClientId,
          plugin_name: 'login',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.callLogin();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement('script');
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }

  linkedinLogin() {
    this.oauth.logInVia(this.linkedinProvider).then(
      (success) => {
        this.linkedinToken = success['code'];
        localStorage.setItem('linkedin-auth-code', this.linkedinToken);
        localStorage.removeItem('fbId');
        localStorage.removeItem('google-auth-id');

        this.getAccessToken(this.linkedinToken);
      },
      (error) => {
        console.log(error);
        console.log(JSON.stringify(error));
      }
    );
  }

  fbLogin() {
    this.accountService.fbLogin()
      .subscribe((data) => {
        console.log('here', data);
        this.fbId = data.id;
        this.fbAccessToken = data.accessToken;

        localStorage.setItem('fbId', this.fbId);
        localStorage.setItem(`fblst_${environment.facebookAppId}`, this.fbAccessToken);
        localStorage.removeItem('google-auth-id');
        localStorage.removeItem('linkedin-access-code');
        localStorage.removeItem('linkedin-auth-code');

        this.accountService
          .addCampaignPlatform(data.id, 'meta')
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              this.isMetaAdInfoShow = res[0] && res[0]['ad_account_id'] ? false : true;

              const accessToken = localStorage.getItem(`fblst_${environment.facebookAppId}`);

              this.campaignService
                .getMetaBusinesses(accessToken)
                .subscribe((data: any) => {
                  this.metaBusinesses = data.results;
                  this.filteredMetaBusiness.next(this.metaBusinesses.slice());
                  this.metaBusinessFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                      this.filterMetaBusiness();
                    });
                });
            },
            error: () => {
              console.log('Error');
            },
          });
      })
  }

  getAccessToken(token: string) {
    this.accountService.getLinkedinAccessCode(token).subscribe(
      (data: any) => {
        console.log(data);
        localStorage.setItem('linkedin-access-code', data.accessToken);

        this.getLinkedinProfile(data.accessToken);
      },
      (error) => {
        alert(2);
        console.error('Error fetching organization info:', error);
      }
    );
  }



  getLinkedinProfile(accessCode: string) {
    this.accountService
      .getLinkedinProfile(accessCode)
      .subscribe((data: any) => {
        this.linkedinId = data.id;
        this.platform = 'linkedin';

        this.accountService
          .addCampaignPlatform(data.id, 'linkedin')
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              //this.isAdInfoShow = res[0]['ad_account_id'] > 0 ? false : true;
              if (res[0]) {
                this.isAdInfoShow = res[0]['ad_account_id'] > 0 ? false : true;
              } else {
                this.isAdInfoShow = true;
              }

              this.campaignService
                .getAdAccounts(accessCode)
                .subscribe((data: any) => {
                  this.adAccounts = data.results;
                  this.filteredAdAccount.next(this.adAccounts.slice());
                  this.adAccountFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                      this.filterAdAccount();
                    });
                });
            },
            error: () => {
              console.log('Error');
            },
          });
      });
  }

  storeBudget(platform: string) {

    const platformId =
      platform === 'google' ? this.googleAuthId : platform === 'meta' ? this.fbId : this.linkedinId;
    const threshold =
      platform === 'google' ? this.googleThreshold : platform === 'meta' ? this.fbThreshold : this.linkedinThreshold;
    this.platformId = platformId;
    const accessToken = platform === 'meta' ? localStorage.getItem(`fblst_${environment.facebookAppId}`) : '';

    this.errorMsg = "";

    //if (budget < 100) {
      //this.errorMsg = "Daily budget must be a minimum of $100.00"
      //return;
    //}


    this.campaignService
      .storeBudget(platformId, platform, threshold, accessToken)
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          this.store.dispatch(
            new AddCampaignAction({
              platformId: platformId,
              threshold: threshold,
              platform: platform,
            })
          );

          this.store.dispatch(new RemoveLinkedinAdAction());

          this.openStartModal(platform);
        },
        error: () => {
          console.log('Error');
        },
      });
  }

  openStartModal(platformType: string = 'linkedin') {
    this.platformType = platformType;
    this.isStartModalOpen = true;
    this.campaignName = '';
    this.cdr.detectChanges();
  }

  onStartOpenChange(isOpen: boolean) {
    this.isStartModalOpen = isOpen;
    this.cdr.detectChanges();
  }

  openBudgetModal() {
    this.isBudgetOpen = true;

    this.store.dispatch(
      new AddCampaignAction({
        platform: this.platform,
        threshold: this.linkedinThreshold,
        platformId: this.linkedinId,
      })
    );



    this.cdr.detectChanges();
  }

  onBudgetModalOpenChange(isOpen: boolean) {
    this.isBudgetOpen = isOpen;
    this.cdr.detectChanges();
    window.location.reload();
  }

  changeSetting(option: number) {
    this.selectedAdSetting = option;
  }

  saveSetting() {
    if (this.platformType === 'meta') {
      this.router.navigate(['/leads/campaign-creator/first-step']);
    }

    const selectedIndustryIds = this.industryMultiCtrl.value.map((industry) => {
      return industry.id;
    });

    const formData = {
      linkedin_ad_id: this.individualAdId,
      launch_preference: this.selectedAdSetting,
      revenue: JSON.stringify(this.selectedRevenues),
      employees_number: JSON.stringify(this.selectedEmployees),
      industries: JSON.stringify(selectedIndustryIds),
    };

    // tslint:disable-next-line:no-debugger
    debugger;

    if (this.dataService.settingId > 0) {
      this.campaignService
        .updateCustomSetting(this.dataService.settingId, formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            if (res['affectedRows'] > 0) {
              this.store.dispatch(
                new AddSettingAction({
                  id: this.dataService.settingId,
                  linkedinAdId: this.individualAdId,
                  linkedinShareId: this.linkedinShareId,
                  launchPreference: this.selectedAdSetting,
                  revenue: this.selectedRevenues,
                  employees: this.selectedEmployees,
                  industries: this.industryMultiCtrl.value,
                })
              );

              this.platformType === 'google'
                ? this.router.navigate([
                    '/leads/campaign-creator/first-step-google',
                  ])
                : this.router.navigate(['/leads/campaign-creator/first-step']);
            }
          },
          error: () => {
            console.log('Error');
          },
        });
    } else {
      this.campaignService
        .insertCustomSetting(formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            if (res['insertId'] > 0) {
              this.store.dispatch(
                new AddSettingAction({
                  id: res['insertId'],
                  linkedinAdId: this.individualAdId,
                  linkedinShareId: this.linkedinShareId,
                  launchPreference: this.selectedAdSetting,
                  revenue: this.selectedRevenues,
                  employees: this.selectedEmployees,
                  industries: this.industryMultiCtrl.value,
                })
              );

              this.platformType === 'google'
                ? this.router.navigate([
                    '/leads/campaign-creator/first-step-google',
                  ])
                : this.router.navigate(['/leads/campaign-creator/first-step']);
            }
          },
          error: () => {
            console.log('Error');
          },
        });
    }
  }

  editCustomSetting(index: number) {
    const data = this.linkedinAds[index];

    this.dataService.campaign = data;
    this.campaignService
      .getCustomSetting(data['id'])
      .subscribe((setting: any) => {
        const filteredIndustries = this.industries.filter((industry) => {
          return JSON.parse(setting.results.industries).includes(industry.id);
        });
        this.industryMultiCtrl.setValue(filteredIndustries);

        this.linkedinShareId = data['linkedin_ad_id'];

        this.store.dispatch(
          new AddSettingAction({
            id: setting.results.id,
            linkedinAdId: setting.results.linkedin_ad_id,
            linkedinShareId: data['linkedin_ad_id'],
            launchPreference: setting.results.launch_preference,
            revenue: JSON.parse(setting.results.revenue),
            employees: JSON.parse(setting.results.employees_number),
            industries: this.industryMultiCtrl.value,
            introText: setting.results.intro_text,
            image: setting.results.image,
            headline: setting.results.headline,
            destinationUrl: setting.results.destination_url,
            callToAction: setting.results.call_to_action,
          })
        );
      });

    this.store.dispatch(
      new AddLinkedinAdAction({
        id: data['id'],
        name: data['name'],
        pageUrl: data['page_url'],
        timeframe: data['timeframe'],
        adPreference: data['ad_preference'],
        platformid: data['platform_id'],
      })
    );
  }

  revenueChange() {
    this.selectedRevenues = this.revenues
      .filter((revenue) => revenue.checked)
      .map((revenue) => revenue.name);
  }

  employeeChange() {
    this.selectedEmployees = this.employees
      .filter((employee) => employee.checked)
      .map((employee) => employee.name);
  }

  saveRevenue() {
    this.isRevenueSaved = true;
  }

  showRevenue() {
    this.isRevenueSaved = false;
  }

  saveEmployee() {
    this.isEmployeeSaved = true;
  }

  showEmployee() {
    this.isEmployeeSaved = false;
  }

  saveIndustry() {
    this.isIndustrySaved = true;
  }

  showIndustry() {
    this.isIndustrySaved = false;
  }

  onBusinessSelected(event: any): void {
    const accessToken = localStorage.getItem(`fblst_${environment.facebookAppId}`);
    const selectedBusiness = event.value;

    this.campaignService
      .getMetaAdAccounts(accessToken, selectedBusiness.id)
      .subscribe((data: any) => {
        this.metaAdAccounts = data.results;
        this.filteredMetaAdAccount.next(this.metaAdAccounts.slice());
        this.metaAdAccountFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterMetaAdAccount();
          });
      });
  }

  saveAdAccountInfo() {
    this.campaignService
      .storeAdAccountInfo(
        this.linkedinId,
        'linkedin',
        this.adAccountCtrl.value.id,
        this.adAccountCtrl.value.reference
      )
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          if (res['affectedRows'] > 0) {
            this.isAdInfoShow = false;
          }
        },
        error: () => {
          console.log('Error');
        },
      });
  }

  saveMetaAdAccountInfo() {
    this.campaignService
      .storeAdAccountInfo(
        this.fbId,
        'meta',
        this.metaAdAccountCtrl.value.account_id,
        ''
      )
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          if (res['affectedRows'] > 0) {
            this.isMetaAdInfoShow = false;
          }
        },
        error: () => {
          console.log('Error');
        },
      });
  }

  updateAdStatus(ad: any, isActive: boolean) {
    this.campaignService
      .changeLinkedinCampaignStatus(
        ad.id,
        ad.linkedin_campaign_id,
        localStorage.getItem('linkedin-access-code'),
        isActive ? 'ACTIVE' : 'PAUSED'
      )
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          ad.status = isActive ? 'active' : 'archived';
        },
        error: () => {
          console.error('Error');
        },
      });
  }

  updateLinkedInAdStatus(ad: any, isActive: boolean) {
    ad.status = isActive ? 'active' : 'paused';
    this.cdr.detectChanges();
    this.campaignService
      .changeLinkedinCampaignStatus(
        ad.id,
        ad.linkedin_campaign_id,
        localStorage.getItem('linkedin-access-code'),
        isActive ? 'ACTIVE' : 'PAUSED'
      )
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          window.location.reload();
        },
        error: () => {
          console.error('Error');
        },
      });

  }

  archiveDeleteLinkedInAd(ad: any) {

    if (ad.linkedin_ad_id) {
      ad.status = 'archived';
      this.campaignService
      .changeLinkedinCampaignStatus(
        ad.id,
        ad.linkedin_campaign_id,
        localStorage.getItem('linkedin-access-code'),
        ad.status.toUpperCase()
      )
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          window.location.reload();
        },
        error: () => {
          console.error('Error');
        },
      });

    } else {
      ad.status = 'deleted';

        this.campaignService
          .deleteLinkedinCampaign(
            ad.id
          )
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              window.location.reload();
            },
            error: () => {
              console.error('Error');
            },
          });

    }
  }







  private generateState(length: number): string {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
