import { Injectable } from '@angular/core';

declare const FB: any;

@Injectable({ providedIn: 'root' })
export class FacebookService {
  private sdkReady: Promise<void>;

  constructor() {
    this.sdkReady = new Promise<void>((resolve, reject) => {
      window['fbAsyncInit'] = () => {
        FB.init({
          appId: '1039427400479827',
          cookie: true,
          xfbml: true,
          version: 'v10.0'
        });
        console.log('Facebook SDK initialized');
        resolve();
      };

      // Load the SDK script
      const scriptId = 'facebook-jssdk';
      if (document.getElementById(scriptId)) {
        console.log('Facebook SDK script already loaded');
        resolve(); // Resolve immediately if the script is already loaded
        return;
      }
      const js = document.createElement('script');
      js.id = scriptId;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.onload = () => {
        console.log('Facebook SDK script loaded');
      };
      js.onerror = (error) => {
        console.error('Error loading Facebook SDK script', error);
        reject(error);
      };
      document.head.appendChild(js);
    });
  }

  isSdkReady(): Promise<void> {
    return this.sdkReady;
  }
}