import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Campaign } from '@app/store/models/campaign.model';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/store/models/state.model';
import { CampaignService } from '@app/_services';
import { environment } from '@environments/environment';
import { AddCampaignAction } from '@app/store/actions/campaigns.actions';
import { first } from 'rxjs/operators';
import { RemoveLinkedinAdAction } from '@app/store/actions/linkedinAds.actions';

@Component({
  selector: 'app-budget-modal',
  templateUrl: './budget-modal.component.html',
  styleUrls: ['./budget-modal.component.scss']
})
export class BudgetModalComponent {
  @Input() isBudgetModalOpen: boolean;
  @Output() isBudgetModalOpenChange = new EventEmitter<boolean>();

  platform: string = "";
  platformId: string = "";
  budget: number = 0;
  threshold: number = 0;

  errorMsg = "";

  constructor(
    private store: Store<AppState>,
    private campaignService: CampaignService
  ) {
    store.pipe(select('campaigns')).subscribe((data: Campaign) => {
      this.threshold = data.threshold;
      this.platform = data.platform;
      this.platformId = data.platformId;
    });
  }

  closeModal() {
    this.isBudgetModalOpenChange.emit(false);
  }

  storeBudget() {

    this.errorMsg = "";

//    if (this.budget < 100) {
  //    this.errorMsg = "Daily budget must be a minimum of $100.00";
    //  return;
    //}

    const accessToken = this.platform === 'meta' ? localStorage.getItem(`fblst_${environment.facebookAppId}`) : '';



    this.campaignService
      .storeBudget(this.platformId, this.platform, this.threshold, accessToken)
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          this.store.dispatch(
            new AddCampaignAction({
              platformId: this.platformId,
              threshold: this.threshold,
              platform: this.platform,
            })
          );

          this.store.dispatch(new RemoveLinkedinAdAction());

        },
        error: () => {
          console.log('Error');
        },
      });


    this.isBudgetModalOpenChange.emit(false);
  }

}
