import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  private url = `${baseUrl}/tracker/campaign`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  storeBudget(
    platformId: string,
    platform: string,
    threshold: number,
    accessToken: string,
  ) {
    return this.http.post(`${this.url}/store_budget`, {
      platformId,
      platform,
      threshold,
      accessToken,
    });
  }

  startCampaign(
    platformId: string,
    businessName: string,
    name: string,
    pageUrl: string,
    status: string,
    timeframe: number,
    preference: number,
    linkedincampaign: number
  ) {
    return this.http.post(`${this.url}/start_campaign`, {
      platformId,
      businessName,
      name,
      pageUrl,
      status,
      timeframe,
      preference,
      linkedincampaign,
    });
  }

  getOrganizationInfo(url: string, accessCode: string) {
    return this.http
      .get(
        `${this.url}/get_organization_info?url=${url}&accessCode=${accessCode}`
      )
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getUploadedFiles(userId: any) {
    return this.http.get(`${this.url}/get_upload_files/${userId}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadFile(file: File, userId: any) {
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post(`${this.url}/upload_file/${userId}`, formData);
  }

  uploadFileIntoLinkedin(src: string, accessCode: string) {
    return this.http.post(`${this.url}/upload_file_into_linkedin`, {
      src,
      accessCode,
    });
  }

  getIndividualAd(id: number) {
    return this.http.get(`${this.url}/get_individual_ad?id=${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getIndividualAds(platformId: string) {
    return this.http
      .get(`${this.url}/get_individual_ads?platformId=${platformId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getIndustries() {
    return this.http.get(`${this.url}/get_categories`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getRevenues() {
    return this.http.get(`${this.url}/get_revenues`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEmployees() {
    return this.http.get(`${this.url}/get_employees`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCustomSetting(id: number) {
    return this.http.get(`${this.url}/get_custom_setting?id=${id}`).pipe(
      map((response) => {
        return response;
      })
    );
  }

  insertCustomSetting(body: any) {
    return this.http.post(`${this.url}/store_custom_setting`, body);
  }

  updateCustomSetting(id: number, body: any) {
    return this.http.post(`${this.url}/update_custom_setting/${id}`, body);
  }

  createLinkedinCampaign(body: any) {
    return this.http.post(`${this.url}/create_linkedin_campaign`, body);
  }

  createLinkedinAd(body: any) {
    return this.http.post(`${this.url}/create_linkedin_ad`, body);
  }

  updateLinkedinAd(id: number, body: any) {
    return this.http.post(`${this.url}/update_linkedin_ad/${id}`, body);
  }

  getAdAccounts(accessCode: any) {
    return this.http
      .get(`${this.url}/get_linkedin_ad_accounts?accessCode=${accessCode}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  storeAdAccountInfo(
    platformId: string,
    platform: string,
    adAccountId: number,
    urn: string
  ) {
    return this.http.post(`${this.url}/store_ad_account_info`, {
      platformId,
      platform,
      adAccountId,
      urn,
    });
  }

  changeLinkedinCampaignStatus(
    adId: number,
    campaignId: number,
    accessCode: string,
    status: string
  ) {
    return this.http.post(`${this.url}/change_linkedin_campaign_status`, {
      adId,
      campaignId,
      accessCode,
      status,
    });
  }

  deleteLinkedinCampaign(
    adId: number
  ) {
    return this.http.post(`${this.url}/delete_linkedin_campaign`, {
      "id":adId
    });
  }

  createGoogleCampaign(body: any) {
    return this.http.post(`${this.url}/create_google_campaign`, body);
  }

  createMetaCampaign(body: any) {
    return this.http.post(`${this.url}/create_meta_campaign`, body);
  }

  createMetaAd(body: any) {
    return this.http.post(`${this.url}/create_meta_ad`, body);
  }

  getMetaBusinesses(accessToken: any) {
    return this.http
      .get(`${baseUrl}/get_meta_businesses?accessToken=${accessToken}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  getMetaAdAccounts(accessToken: any, businessId: any) {
    return this.http
      .get(`${baseUrl}/get_meta_ad_accounts?accessToken=${accessToken}&businessId=${businessId}`)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
